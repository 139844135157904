import React from "react";
import PropTypes from "prop-types";
require("core-js/fn/array/from");

import { FaHome, FaEnvelope, FaUser, FaRegNewspaper } from "react-icons/fa/";
import { useTheme } from "../../context/ThemeContext";

import Item from "./Item";
import Expand from "./Expand";
import ThemeToggle from "../ThemeToggle/ThemeToggle";

const theme = {
  space: {
    s: '0.5rem',
    m: '1rem',
    l: '2rem',
    inset: {
      s: '0.5rem',
      m: '1rem',
      l: '2rem'
    }
  },
  time: {
    duration: {
      default: '0.3s'
    }
  },
  size: {
    radius: {
      small: '4px'
    }
  }
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.itemList = React.createRef();

    this.items = [
      { to: "/", label: "Home", icon: FaHome },
      //{ to: "/tags/", label: "Tags", icon: FaTag },
      //{ to: "/search/", label: "Search", icon: FaSearch },
      { to: "/blog/", label: "Blog", icon: FaRegNewspaper },
      // { to: "/contact/", label: "Contato", icon: FaEnvelope },
      { to: "/about/", label: "Sobre mim", icon: FaUser }
    ];

    this.renderedItems = []; // will contain references to rendered DOM elements of menu
  }

  state = {
    open: false,
    hiddenItems: []
  };

  static propTypes = {
    path: PropTypes.string.isRequired,
    fixed: PropTypes.bool.isRequired,
    screenWidth: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.renderedItems = this.getRenderedItems();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.path !== prevProps.path ||
      this.props.fixed !== prevProps.fixed ||
      this.props.screenWidth !== prevProps.screenWidth
    ) {
      if (this.props.path !== prevProps.path) {
        this.closeMenu();
      }
      this.hideOverflowedMenuItems();
    }
  }

  getRenderedItems = () => {
    const { itemList } = this;
    return itemList.current ? Array.from(itemList.current.children) : [];
  };

  hideOverflowedMenuItems = () => {
    const { screenWidth } = this.props;
    const items = this.getRenderedItems();
    const menuWidth = screenWidth - 100; // 100px for logo
    let currentWidth = 0;
    const hiddenItems = [];

    items.forEach(item => {
      const itemWidth = item.offsetWidth;
      if (currentWidth + itemWidth > menuWidth) {
        hiddenItems.push(this.items[items.indexOf(item)]);
      } else {
        currentWidth += itemWidth;
      }
    });

    this.setState({ hiddenItems });
  };

  toggleMenu = e => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  closeMenu = e => {
    if (this.state.open) {
      this.setState({ open: false });
      if (this.props.screenWidth < 1024) {
        this.renderedItems.map(item => {
          if (item.classList.contains("showItem")) {
            item.classList.add("hideItem");
            item.classList.remove("item");
          }
        });
      }
    }
  };

  render() {
    const { screenWidth } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <nav className={`menu ${open ? "open" : ""}`} rel="js-menu">
          <ul className="itemList" ref={this.itemList}>
            {this.items.map(item => (
              <Item item={item} key={item.label} icon={item.icon} />
            ))}
            <li className="theme-toggle">
              <ThemeToggle />
            </li>
          </ul>
          {this.state.hiddenItems.length > 0 && <Expand onClick={this.toggleMenu} />}
          {open && screenWidth >= 1024 && (
            <ul className="hiddenItemList">
              {this.state.hiddenItems.map(item => (
                <Item item={item} key={item.label} hiddenItem />
              ))}
            </ul>
          )}
        </nav>

        {/* --- STYLES --- */}
        <style jsx>{`
          .menu {
            align-items: center;
            background: var(--menu-background);
            top: 0;
            display: flex;
            flex-grow: 1;
            left: 0;
            max-height: ${open ? "1000px" : "50px"};
            padding: 0 ${theme.space.inset.s};
            position: fixed;
            width: 100%;
            z-index: 1;
            transition: all ${theme.time.duration.default};
          }

          .itemList {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0 ${theme.space.s};
            position: relative;
            width: 100%;
          }

          .theme-toggle {
            display: flex;
            align-items: center;
            margin-left: ${theme.space.s};
          }

          @media (max-width: 1023px) {
            .menu {
              &::after {
                position: absolute;
                content: "";
                left: ${theme.space.m};
                right: ${theme.space.m};
                top: 0;
                background: var(--accent);
              }

              &.open {
                padding: ${theme.space.inset.m};
              }

              :global(.homepage):not(.fixed) & {
                bottom: -100px;
              }
            }
          }

          @media (min-width: 1024px) {
            .menu {
              border-top: none;
              background: var(--menu-background);
              display: flex;
              position: relative;
              justify-content: flex-end;
              padding-left: 50px;
              transition: none;
            }

            .itemList {
              justify-content: flex-end;
              padding: 0;
            }

            .hiddenItemList {
              list-style: none;
              margin: 0;
              position: absolute;
              background: var(--menu-background);
              border: 1px solid var(--menu-border);
              top: 48px;
              right: 0;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              padding: ${theme.space.m};
              border-radius: ${theme.size.radius.small};
              border-top-right-radius: 0;

              &:after {
                content: "";
                background: var(--menu-background);
                z-index: 10;
                top: -10px;
                right: -1px;
                width: 44px;
                height: 10px;
                position: absolute;
                border-left: 1px solid var(--menu-border);
                border-right: 1px solid var(--menu-border);
              }

              :global(.homepage):not(.fixed) & {
                border: 1px solid var(--menu-border);
                background: var(--menu-background);
                top: 50px;

                &:after {
                  top: -11px;
                  border-left: 1px solid var(--menu-border);
                  border-right: 1px solid var(--menu-border);
                  background: var(--menu-background);
                }
              }

              :global(.fixed) & {
                top: 44px;
              }
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

export default Menu;
