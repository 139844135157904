/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
require("prismjs/themes/prism-solarizedlight.css")
require("./src/styles/prism-overrides.css")

import { GlobalState } from "./src/components/GlobalState/GlobalState.js"
import { ThemeProvider } from './src/context/ThemeContext'
import GlobalStyles from './src/styles/GlobalStyles'

export const wrapRootElement = ({ element }) => {
    return (
        <ThemeProvider>
            <GlobalStyles />
            <GlobalState>
                {element}
            </GlobalState>
        </ThemeProvider>
    )
}