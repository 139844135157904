export const theme = {
  space: {
    xs: '0.25rem',
    s: '0.5rem',
    m: '1rem',
    l: '2rem',
    inset: {
      s: '0.5rem',
      m: '1rem',
      l: '2rem'
    }
  },
  time: {
    duration: {
      default: '0.3s'
    }
  },
  size: {
    radius: {
      small: '4px'
    }
  }
}; 