export const lightTheme = {
  primary: "#FF375F",
  secondary: "#f9ebd2",
  background: "#ffffff",
  text: "#2C3E50",
  textLight: "#666666",
  border: "#e5e5e5",
  accent: "#FF375F",
  menu: {
    background: "#ffffff",
    border: "#e5e5e5",
    text: "#2C3E50",
    hover: "#e5e5e5"
  },
  card: {
    background: "#ffffff",
    border: "#e5e5e5",
    text: "#2C3E50"
  },
  button: {
    background: "#FF375F",
    text: "#ffffff",
    hover: "#FF4B2B"
  },
  textSecondary: '#666666',
  link: '#2C3E50',
  linkHover: '#2C3E50',
  codeBackground: '#F6F8FA',
  codeText: '#24292E',
  blockquoteBackground: '#F6F8FA',
  blockquoteBorder: '#DFE2E5',
  tableBorder: '#DFE2E5',
  tableHeaderBackground: '#F6F8FA',
  tableRowHover: '#F6F8FA',
  selectionBackground: '#a8c7fa',
  selectionText: '#FFFFFF',
  scrollbarTrack: '#F1F1F1',
  scrollbarThumb: '#C1C1C1',
  scrollbarThumbHover: '#A8A8A8'
};

export const darkTheme = {
  primary: "#FF375F",
  secondary: "#2d2d2d",
  background: "#121212",
  text: "#F5F5F5",
  textLight: "#E0E0E0",
  border: "#333333",
  accent: "#FF375F",
  menu: {
    background: "#1a1a1a",
    border: "#333333",
    text: "#F5F5F5",
    hover: "#333333"
  },
  card: {
    background: "#1a1a1a",
    border: "#333333",
    text: "#F5F5F5"
  },
  button: {
    background: "#FF375F",
    text: "#ffffff",
    hover: "#FF4B2B"
  },
  textSecondary: '#E0E0E0',
  link: '#F5F5F5',
  linkHover: '#F5F5F5',
  codeBackground: '#1C1C1E',
  codeText: '#E5E5EA',
  blockquoteBackground: '#1C1C1E',
  blockquoteBorder: '#38383A',
  tableBorder: '#38383A',
  tableHeaderBackground: '#1C1C1E',
  tableRowHover: '#2C2C2E',
  selectionBackground: '#aac7fa',
  selectionText: '#FFFFFF',
  scrollbarTrack: '#1C1C1E',
  scrollbarThumb: '#38383A',
  scrollbarThumbHover: '#484848'
}; 