import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaEllipsisH } from "react-icons/fa";
import { theme } from "../../styles/styledTheme";

const ExpandButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${theme.space.s};
  display: flex;
  align-items: center;
  color: var(--menu-text);
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Expand = ({ onClick }) => (
  <ExpandButton onClick={onClick} aria-label="Expandir menu">
    <FaEllipsisH />
  </ExpandButton>
);

Expand.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default Expand;
