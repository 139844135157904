import { createGlobalStyle } from 'styled-components';
import { lightTheme, darkTheme } from './theme';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary: ${lightTheme.primary};
    --secondary: ${lightTheme.secondary};
    --background: ${lightTheme.background};
    --text: ${lightTheme.text};
    --text-light: ${lightTheme.textLight};
    --border: ${lightTheme.border};
    --accent: ${lightTheme.accent};
    --menu-background: ${lightTheme.menu.background};
    --menu-border: ${lightTheme.menu.border};
    --menu-text: ${lightTheme.menu.text};
    --menu-hover: ${lightTheme.menu.hover};
    --card-background: ${lightTheme.card.background};
    --card-border: ${lightTheme.card.border};
    --card-text: ${lightTheme.card.text};
    --button-background: ${lightTheme.button.background};
    --button-text: ${lightTheme.button.text};
    --button-hover: ${lightTheme.button.hover};
    --selection-background: ${lightTheme.selectionBackground};
    --selection-text: ${lightTheme.selectionText};
  }

  [data-theme='dark'] {
    --primary: ${darkTheme.primary};
    --secondary: ${darkTheme.secondary};
    --background: ${darkTheme.background};
    --text: ${darkTheme.text};
    --text-light: ${darkTheme.textLight};
    --border: ${darkTheme.border};
    --accent: ${darkTheme.accent};
    --menu-background: ${darkTheme.menu.background};
    --menu-border: ${darkTheme.menu.border};
    --menu-text: ${darkTheme.menu.text};
    --menu-hover: ${darkTheme.menu.hover};
    --card-background: ${darkTheme.card.background};
    --card-border: ${darkTheme.card.border};
    --card-text: ${darkTheme.card.text};
    --button-background: ${darkTheme.button.background};
    --button-text: ${darkTheme.button.text};
    --button-hover: ${darkTheme.button.hover};
    --selection-background: ${darkTheme.selectionBackground};
    --selection-text: ${darkTheme.selectionText};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: var(--background);
    color: var(--text);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.6;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  ::selection {
    background-color: var(--selection-background);
    color: var(--selection-text);
  }

  a {
    color: var(--text);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--accent);
    }
    
    &.button {
      color: white;
      
      &:hover {
        color: white;
      }
    }
  }

  .button, button, input[type="button"], input[type="submit"] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 1.5rem;
    color: white;
    background-color: var(--primary);
    border-radius: 8px;
    text-decoration: none;
    font-weight: 600;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;
    
    &:hover {
      background-color: var(--button-hover);
      transform: translateY(-2px);
    }
  }
  
  .gradient-button {
    background: linear-gradient(135deg, var(--primary) 0%, var(--button-hover) 100%);
    box-shadow: 0 10px 20px -10px rgba(255, 55, 95, 0.4);
    position: relative;
    overflow: hidden;
    padding: 0.8rem 1.8rem;
    font-size: 1rem;
    border-radius: 8px;
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 14px 24px -10px rgba(255, 55, 95, 0.6);
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50%);
      transform: translateY(-100%);
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    &:hover::before {
      transform: translateY(0);
    }
    
    span {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    span::after {
      content: '→';
      opacity: 0;
      margin-left: -10px;
      transition: all 0.3s ease;
    }
    
    &:hover span::after {
      opacity: 1;
      margin-left: 8px;
    }
  }

  @media (max-width: 768px) {
    .gradient-button {
      padding: 0.7rem 1.6rem;
      font-size: 0.9rem;
    }
  }

  code {
    background-color: var(--card-background);
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 0.9em;
  }

  blockquote {
    border-left: 4px solid var(--accent);
    margin: 0;
    padding-left: 1em;
    color: var(--text-light);
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 1em 0;

    th,
    td {
      border: 1px solid var(--border);
      padding: 0.5em;
      text-align: left;
    }

    th {
      background-color: var(--card-background);
    }
  }

  /* Estilização da scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--background);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--border);
    border-radius: 4px;

    &:hover {
      background: var(--accent);
    }
  }
`;

export default GlobalStyles; 