import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import styled from "styled-components";
import { theme } from "../../styles/styledTheme";

const ItemLink = styled(Link)`
  color: var(--menu-text);
  text-decoration: none;
  padding: ${theme.space.s};
  display: flex;
  align-items: center;
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent);
  }

  svg {
    margin-right: ${theme.space.xs};
  }
`;

const Item = ({ item, icon: Icon, hiddenItem }) => (
  <li className={hiddenItem ? "hiddenItem" : "item"}>
    <ItemLink to={item.to} data-slug={item.to}>
      {Icon && <Icon />}
      {item.label}
    </ItemLink>
  </li>
);

Item.propTypes = {
  item: PropTypes.shape({
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  icon: PropTypes.func,
  hiddenItem: PropTypes.bool
};

export default Item;
