import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

const Footer = props => {
  const { theme } = props;
  const buildTime = useStaticQuery(query).site.buildTime;

  return (
    <React.Fragment>
      <footer className="footer">
        <p>© Décio Montanhani {new Date().getFullYear()}</p>
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer {
          background: var(--background);
          padding: ${theme.space.inset.default};
          text-align: center;
          color: var(--text);
          font-size: ${theme.font.size.xxs};
          width: 100%;
          height: 60px;
          border-top: 1px solid var(--border);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          margin: 0;
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Footer;

const query = graphql`
  query Info {
    site {
      buildTime(formatString: "DD/MM/YYYY HH:mm")
    }
  }
`;
